<script>
import {defineComponent} from 'vue'
import TranslationInput from "../elements/translation-input.vue";
import AdditionalField from "../elements/additional-field.vue";
import AdditionalVersionableField from "../elements/additional-versionable-field.vue";
import {mapGetters} from "vuex";
import RelatedUserManager from "../elements/related-user-manager.vue";
import Swal from "sweetalert2";
import UserSelectorAlt from "../elements/user-selector-alt.vue";
/*import Swal from "sweetalert2";*/

export default defineComponent({
    name: "project-form",

    emits: ['saved'],

    props: {
        project: {
            type: Object,
            required: true
        },

        own: {
            type: Boolean,
            required: true
        },

        requiredFields: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    components: {
        UserSelectorAlt,
        RelatedUserManager, TranslationInput, AdditionalField, AdditionalVersionableField},

    computed: {
        ...mapGetters({
            'loading': 'project/loading',
            'states': 'project/states',
            'errors': 'project/errors',
            admin: 'auth/admin',
            projectTypes: 'projectType/all',
            processes: 'process/all',
            steps: 'step/all',
            statuses: 'step/allStatuses',
            users: 'user/selectList',
            // models: 'model/list',
            fields: 'project/fields',
            fieldVersions: 'fieldVersion/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        userModels: function () {
            return [
                {
                    value: 'App\\Models\\User',
                    label: this.$tc('users.user', 2).ucFirst()
                }
            ]
        },

        modelStore: function () {
            if (! this.model.target_type) {
                return
            }

            return  this.model.target_type.substring(this.model.target_type.lastIndexOf('\\') + 1).lcFirst();
        },

        modelPalette: function () {
            if (! this.modelStore) {
                return []
            }

            return this.$store.getters[`${this.modelStore}/selectList`]
        },

        closed: function () {
            return !!this.$store.state.project.item?.closed_at;
        },

        canceled: function () {
            return !!this.$store.state.project.item?.canceled_at;
        },

        disabledClosedAt: function () {
            return !!this.$store.state.project.item?.closed_at // && !this.admin
        },

        readonly: function () {
            return !!this.$store.state.project.item?.closed_at || !!this.$store.state.project.item?.canceled_at;
        },

        models: function () {
            return [
                {
                    name: 'Appon\\Partners\\Models\\Partner',
                    translation_key: 'partners::partners.partner'
                }
            ]
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`project/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    const translationKey = this.own ? 'routes./projects/my-projects/:id(\\d+)' : 'routes./projects/:id(\\d+)'
                    this.$router.push(this.$t(translationKey, {id: this.model.id}).rmValidation())
                }
            })
        },

        cancel: function () {
            Swal.fire({
                icon: 'question',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-success border-success',
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.cancel_it').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.model.canceled_at = (new Date()).formattedDate();
                    this.save();
                }
            });
        },

        close: function () {
            Swal.fire({
                icon: 'question',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-success border-success',
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.close_it').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.model.closed_at = (new Date()).formattedDate();
                    this.save();
                }
            });
            /*Swal.fire({
                icon: 'success',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                },
                denyButtonText: this.$t('projects::projects.columns.failed').ucFirst(),
                confirmButtonText: this.$t('projects::projects.columns.successful').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                this.model.closed_at = (new Date()).formattedDate();
                if (result.isConfirmed) {
                    this.model.closed_at = (new Date()).formattedDate();
                    this.model.successful = true;
                    this.model.failed = false;
                }
                if (result.isDenied) {
                    this.model.closed_at = (new Date()).formattedDate();
                    this.model.successful = false;
                    this.model.failed = true;
                }
                if (result.value !== undefined) {
                    this.save();
                }
            });*/
        },

        setModel: function (model = {}) {
            const base = {
                target_type: 'Appon\\Partners\\Models\\Partner',
                user_type: 'App\\Models\\User'
            }

            if (!this.can('projects.admin')) {
                base.retrospective_validation = true
            }

            this.model = Object.assign(
                {},
                base,
                JSON.parse(JSON.stringify(model))
            )
        },

        fetchModels: function () {
            this.$store.dispatch('model/list')
        },

        fetchModelPalette: function () {
            if (! this.modelStore) {
                return;
            }

            this.$store.dispatch(`${this.modelStore}/selectList`);
        },

        fetchUsers: function () {
            this.$store.dispatch('user/selectList');
        },

        fetchTypes: function () {
            this.$store.dispatch('projectType/all');
        },

        fetchProcesses: function () {
            this.$store.dispatch('process/all').then(() => {
                if (this.processes.length === 1) {
                    this.model.process_id = this.processes[0]?.id
                }
            })
        },

        fetchStatuses: function () {
            if (this.model.step_id) {
                this.$store.dispatch('step/allStatuses', { relatedTo: { relation: 'steps', id: this.model.step_id } });
            } else {
                this.$store.dispatch('step/clearAllStatuses')
            }
        },

        fetchSteps: function () {
            if (this.model.process_id) {
                this.$store.dispatch('step/all', { process_id: this.model.process_id , sorting: { column: 'sort', desc: false }})
            } else {
                this.$store.dispatch('step/clearAll')
            }
        },

        isRequiredField: function (field) {
            return this.requiredFields?.includes(field);
        },
    },

    watch: {
        project: function (value) {
            this.setModel(value)
        },

        'model.target_type': {
            immediate: true,
            deep: true,
            handler: function () {
                this.fetchModelPalette()
            },
        },

        'model.step_id': {
            immediate: true,
            deep: true,
            handler: function () {
                this.fetchStatuses()
            },
        },

        'model.process_id': {
            immediate: true,
            deep: true,
            handler: function () {
                this.fetchSteps()
            },
        }
    },

    created() {
        console.log(this.$router)
        this.setModel(this.project)
        this.fetchUsers()
        // this.fetchModels()
        this.fetchModelPalette()
        this.fetchTypes()
        this.fetchProcesses()
        this.$store.dispatch('project/fields', {owner_type: 'Appon\\Projects\\Models\\Project', with: 'fieldType'})
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-12 col-xl-10 pe-xl-4">
                    <div class="row">
                        <div class="col-lg-3 mb-4">
                            <label
                                :class="{'text-danger': states.name === false}"
                                class="form-label"
                                for="name"
                            >
                                <span>{{ $t('projects::projects.columns.name').ucFirst() }}</span>
                                <span v-if="isRequiredField('name') || true" class="ms-2 text-danger">*</span>
                            </label
                            >
                            <input
                                id="name"
                                v-model="model.name"
                                :class="{'border-danger': states.name === false || isRequiredField('name')}"
                                :placeholder="$t('projects::projects.placeholders.name').ucFirst()"
                                class="form-control"
                                name="name"
                                type="text"
                                :disabled="readonly"
                            />
                            <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                        </div>

                        <div class="col-lg-2 mb-4" v-if="false">
                            <label
                                :class="{'text-danger': states.translation_key === false}"
                                class="form-label"
                                for="translation_key"
                            >{{ $t('projects::projects.columns.translation_key').ucFirst() }}</label
                            >
                            <translation-input
                                v-model="model.translation_key"
                                :error="errors.translation_key"
                                :placeholder="$t('projects::projects.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                                :state="states.translation_key"
                            ></translation-input>
                            <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                                {{ errors.translation_key }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4">
                            <label
                                :class="{'text-danger': states.project_type_id === false}"
                                class="form-label"
                                for="project_type_id"
                            >
                                <span>{{ $t('projects::projects.columns.project_type_id').ucFirst() }}</span>
                                <span v-if="isRequiredField('project_type_id')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="project_type_id"
                                v-model="model.project_type_id"
                                :class="{'border-danger': states.project_type_id === false || isRequiredField('project_type_id')}"
                                class="form-select"
                                name="project_type_id"
                                :disabled="readonly"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.project_type_id').ucFirst() }}
                                </option>
                                <option v-for="(projectType, key) in projectTypes" :key="key" :value="projectType.id">{{
                                        projectType.translation_key ? $t(projectType.translation_key).ucFirst() : projectType.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.project_type_id === false}" class="invalid-feedback">
                                {{ errors.project_type_id }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4" v-if="processes.length !== 1">
                            <label
                                :class="{'text-danger': states.process_id === false}"
                                class="form-label"
                                for="process_id"
                            >
                                <span>{{ $t('projects::projects.columns.process_id').ucFirst() }}</span>
                                <span v-if="isRequiredField('process_id')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="process_id"
                                v-model="model.process_id"
                                :class="{'border-danger': states.process_id === false || isRequiredField('process_id')}"
                                class="form-select"
                                name="process_id"
                                :disabled="readonly"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.process_id').ucFirst() }}
                                </option>
                                <option v-for="(process, key) in processes" :key="key" :value="process.id">{{
                                        process.translation_key ? $t(process.translation_key).ucFirst() : process.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.process_id === false}" class="invalid-feedback">
                                {{ errors.process_id }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4"  v-if="can('projects.admin') && model.id">
                            <label
                                :class="{'text-danger': states.step_id === false}"
                                class="form-label"
                                for="step_id"
                            >
                                <span>{{ $t('projects::projects.columns.step_id').ucFirst() }}</span>
                                <span v-if="isRequiredField('step_id')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="step_id"
                                v-model="model.step_id"
                                :class="{'border-danger': states.step_id === false || isRequiredField('step_id')}"
                                class="form-select"
                                name="step_id"
                                :disabled="!this.model.process_id || readonly"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.step_id').ucFirst() }}
                                </option>
                                <option v-for="(step, key) in steps" :key="key" :value="step.id">{{
                                        (key + 1) + '. ' + (step.translation_key ? $t(step.translation_key).ucFirst() : step.name)
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.step_id === false}" class="invalid-feedback">
                                {{ errors.step_id }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4"  v-if="can('projects.admin') && model.id">
                            <label
                                :class="{'text-danger': states.status_id === false}"
                                class="form-label"
                                for="status_id"
                            >
                                <span>{{ $t('projects::projects.columns.status_id').ucFirst() }}</span>
                                <span v-if="isRequiredField('status_id')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="status_id"
                                v-model="model.status_id"
                                :class="{'border-danger': states.status_id === false || isRequiredField('status_id')}"
                                class="form-select"
                                name="status_id"
                                :disabled="!this.model.process_id || readonly"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.status_id').ucFirst() }}
                                </option>
                                <option v-for="(status, key) in statuses" :key="key" :value="status.id">{{
                                        status.translation_key ? $t(status.translation_key).ucFirst() : status.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.status_id === false}" class="invalid-feedback">
                                {{ errors.status_id }}
                            </div>
                        </div>
                        <div class="col-lg-3 mb-4" v-if="!model.target_type">
                            <label
                                :class="{'text-danger': states.target_type === false}"
                                class="form-label"
                                for="target_type"
                            >
                                <span>{{ $t('projects::projects.columns.target_type').ucFirst() }}</span>
                                <span v-if="isRequiredField('target_type')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="target_type"
                                v-model="model.target_type"
                                :class="{'border-danger': states.target_type === false || isRequiredField('target_type')}"
                                class="form-select"
                                name="target_type"
                                :disabled="readonly"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.target_type').ucFirst() }}
                                </option>
                                <option v-for="(model, key) in models" :key="key" :value="model.name">{{
                                        model.translation_key ? $t(model.translation_key).ucFirst() : model.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.target_type === false}" class="invalid-feedback">
                                {{ errors.target_type }}
                            </div>
                        </div>
                        <div class="col-lg-3 mb-4">
                            <label
                                :class="{'text-danger': states.target_id === false}"
                                class="form-label"
                                for="target_id"
                            >
                                <span>{{ (model.target_type ? $tc('partners::partners.partner', 1).ucFirst() : $t('projects::projects.columns.target_id').ucFirst()) }}</span>
                                <span v-if="isRequiredField('target_id') || true" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="target_id"
                                v-model="model.target_id"
                                :class="{'border-danger': states.target_id === false || isRequiredField('target_id')}"
                                class="form-select"
                                name="target_id"
                                :disabled="readonly"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.target_id').ucFirst() }}
                                </option>
                                <option v-for="(item, key) in modelPalette" :key="key" :value="item.id">{{
                                        item.translation_key ? $t(item.translation_key) : item.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.target_id === false}" class="invalid-feedback">
                                {{ errors.target_id }}
                            </div>
                        </div>
                        <div class="col-lg-3 mb-4" v-if="!model.user_type">
                            <label
                                :class="{'text-danger': states.user_type === false}"
                                class="form-label"
                                for="user_type"
                            >
                                <span>{{ $t('projects::projects.columns.user_type').ucFirst() }}</span>
                                <span v-if="isRequiredField('user_type')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="user_type"
                                v-model="model.user_type"
                                :class="{'border-danger': states.user_type === false || isRequiredField('user_type')}"
                                class="form-select"
                                name="user_type"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.user_type').ucFirst() }}
                                </option>
                                <option v-for="(userModel, key) in userModels" :key="key" :value="userModel.id">{{
                                        userModel.label
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.user_type === false}" class="invalid-feedback">
                                {{ errors.user_type }}
                            </div>
                        </div>
                        <div class="col-lg-3 mb-4" v-if="!own">
                            <label
                                :class="{'text-danger': states.user_id === false}"
                                class="form-label"
                                for="user_id"
                            >
                                <span>{{ $t('projects::projects.columns.user_id').ucFirst() }}</span>
                                <span v-if="isRequiredField('user_id')" class="ms-2 text-danger">*</span>
                            </label>
                            <select
                                id="user_id"
                                v-model="model.user_id"
                                :class="{'border-danger': states.user_id === false || isRequiredField('user_id')}"
                                class="form-select"
                                name="user_id"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::projects.placeholders.user_id').ucFirst() }}
                                </option>
                                <option v-for="(user, key) in users" :key="key" :value="user.id">{{
                                        user.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">
                                {{ errors.user_id }}
                            </div>
                        </div>
                        <div class="col-lg-2 mb-4" v-if="can('projects.admin')">
                            <label
                                class="form-label"
                                for="retrospective_validation"
                            >
                                <span>{{ $t('projects::projects.columns.retrospective_validation').ucFirst() }}</span>
                                <span v-if="isRequiredField('retrospective_validation')" class="ms-2 text-danger">*</span>
                            </label
                            >
                            <div class="form-check form-switch form-switch-md mb-3 ps-0" :class="{'border-danger': isRequiredField('retrospective_validation')}">
                                <input id="retrospective_validation" v-model="model.retrospective_validation"
                                       class="form-check-input float-none mx-3" type="checkbox" :disabled="readonly">
                            </div>
                            <div :class="{'d-block': states.retrospective_validation === false}" class="invalid-feedback">
                                {{ errors.retrospective_validation }}
                            </div>
                        </div>
                    </div>
                    <template v-if="fields && fields.length">
                        <template v-for="(field, index) in fields" :key="index">
                            <additional-versionable-field
                                v-if="field.versionable"
                                v-model="model[field.transformed_name]"
                                :is-required="isRequiredField(field.transformed_name)"
                                :is-disabled="readonly"
                                :field="field"
                                :state="states[field.transformed_name]"
                                :error="errors[field.transformed_name]"
                                @update:modelValue="newValue => model[field.transformed_name] = newValue"
                            ></additional-versionable-field>

                            <div v-else class="row mb-4">
                                <additional-field
                                    v-model="model[field.transformed_name]"
                                    :is-required="isRequiredField(field.transformed_name)"
                                    :disabled="readonly"
                                    :field="field"
                                    :state="states[field.transformed_name]"
                                    :error="errors[field.transformed_name]"
                                    @update:modelValue="newValue => model[field.transformed_name] = newValue"
                                ></additional-field>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="col-lg-12 col-xl-2 bg-light rounded">
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <user-selector-alt v-model="model.user_id"></user-selector-alt>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <h4 class="text-center text-dark opacity-75 mb-3">
                                <i class="fas fa-users"></i>
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-4">
                            <div class="p-2 rounded bg-white">
                                <label
                                    :class="{'text-danger': states.users === false}"
                                    class="form-label"
                                    for="users"
                                >{{ $t('projects::projects.relationships.users').ucFirst() }}</label>
                                <related-user-manager v-model="model.users" :disabled="readonly"></related-user-manager>
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <div class="p-2 border bg-white rounded">
                                <label
                                    :class="{'text-danger': states.contributors === false}"
                                    class="form-label"
                                    for="contributors"
                                >{{ $t('projects::projects.relationships.contributors').ucFirst() }}</label>
                                <related-user-manager v-model="model.contributors" contributors-allowed :disabled="readonly"></related-user-manager>
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <div class="bg-white p-2 rounded">
                                <label
                                    :class="{'text-danger': states.followers === false}"
                                    class="form-label"
                                    for="followers"
                                >{{ $t('projects::projects.relationships.followers').ucFirst() }}</label>
                                <related-user-manager v-model="model.followers" :disabled="readonly"></related-user-manager>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4" v-if="model.id && false">
                <div class="col-lg-3"  v-if="can('projects.admin')">
                    <label
                        :class="{'text-danger': states.closed_at === false}"
                        class="form-label"
                        for="closed_at"
                    >
                        <span>{{ $t('projects::projects.columns.closed_at').ucFirst() }}</span>
                        <span v-if="isRequiredField('closed_at')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <input
                        :disabled="disabledClosedAt"
                        id="closed_at"
                        v-model="model.closed_at"
                        :class="{'border-danger': states.closed_at === false || isRequiredField('closed_at')}"
                        :placeholder="$t('projects::projects.placeholders.closed_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.closed_at === false}" class="invalid-feedback">
                        {{ errors.closed_at }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.canceled_at === false}"
                        class="form-label"
                        for="canceled_at"
                    >
                        <span>{{ $t('projects::projects.columns.canceled_at').ucFirst() }}</span>
                        <span v-if="isRequiredField('canceled_at')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <input
                        id="canceled_at"
                        v-model="model.canceled_at"
                        :class="{'border-danger': states.canceled_at === false || isRequiredField('canceled_at')}"
                        :placeholder="$t('projects::projects.placeholders.canceled_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.canceled_at === false}" class="invalid-feedback">
                        {{ errors.canceled_at }}
                    </div>
                </div>
                <div class="col-lg-2" v-if="can('projects.admin')">
                    <label
                        class="form-label"
                        for="failed"
                    >
                        <span>{{ $t('projects::projects.columns.failed').ucFirst() }}</span>
                        <span v-if="isRequiredField('failed')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="failed" v-model="model.failed"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                    <div :class="{'d-block': states.failed === false}" class="invalid-feedback">
                        {{ errors.failed }}
                    </div>
                </div>
                <div class="col-lg-2" v-if="can('projects.admin')">
                    <label
                        class="form-label"
                        for="successful"
                    >{{ $t('projects::projects.columns.successful').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="successful" v-model="model.successful"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                    <div :class="{'d-block': states.successful === false}" class="invalid-feedback">
                        {{ errors.successful }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end mt-4" v-if="!closed && !canceled">
                <div class="col-lg-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        <i class="fas fa-save"></i>
                        <span class="ms-2">{{ btnText }}</span>
                    </button>
                    <slot name="buttons" :save="save"></slot>
                    <button v-if="!closed && !canceled" class="ms-2 btn btn-success" type="submit" @click.prevent="close">
                        <i class="fas fa-lock"></i>
                        <span class="ms-2">{{ $t('base.close_it').ucFirst() }}</span>
                    </button>
                    <button v-if="!closed && !canceled" class="ms-2 btn btn-danger" type="submit" @click.prevent="cancel">
                        <i class="fas fa-times"></i>
                        <span class="ms-2">{{ $t('base.cancel_it').ucFirst() }}</span>
                    </button>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style lang="scss">
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

::v-deep {
    .swal2-styled.swal2-confirm.btn-success {
        background-color: $success !important;

    }
}
.swal2-styled.swal2-confirm.btn-success {
    background-color: $success !important;
    box-shadow: 0 0 0 0.15rem rgba($success, .5);
}
</style>
