<script>
import Avatar from "./user/avatar.vue";

export default {
    name: "user-selector-alt",

    components: {Avatar},

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            type: [String, Number],
        },

        store: {
            type: String,
            default: 'user'
        }
    },

    computed: {
        selectList: function () {
            return this.$store.getters[this.store + '/selectList'];
        },

        model: {
            get: function () {
                return Number(this.modelValue);
            },

            set: function (model) {
                this.$emit('update:modelValue', model);
            },
        },

        user: function () {
            return this.selectList?.find(item => item.id === this.model);
        },
    },

    data: function () {
        return {
            show: false
        };
    },

    methods: {
        fetchSelectList: function () {
            this.$store.dispatch(this.store + '/selectList');
        },

        toggleList: function () {
            this.show = !this.show;
        },

        select: function (id) {
            this.model = id;
            this.show = !this.show;
        },

        scroll: function (event) {
            event.preventDefault();
            this.$refs['list'].scrollLeft += event.deltaY;
        },
    },

    created() {
        this.fetchSelectList();
    }
}
</script>

<template>
    <div class="d-inline-block user-selector-alt">
        <div class="user-selector-alt-wrap rounded-start rounded-end position-relative py-1 px-5" :class="{'bg-soft-primary': show}">
            <avatar class="cursor-pointer" :user="user" size="xl" :link="false" @click="toggleList"></avatar>
            <div v-if="show" class="select-list p-1 pb-2 bg-white border border-1 rounded" @wheel="scroll">
                <div class="list-container d-flex" ref="list">
                    <avatar v-for="(item, key) in selectList" class="d-inline-block opacity-75 opacity-hover-100 cursor-pointer ms-1 my-1" :link="false" :key="key" :user="item" @click="select(item.id)"></avatar>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

.select-list {
    display: block;
    position: absolute;
    right: 0;
    padding-left: 5px;
    padding-right: 5px;
    top: 100%;
    width: 100%;
    z-index: 3;

    .list-container {
        height: 50px;
        width: 100%;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 4px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($dark, .075);
            border-radius: 4px;
            border: 2px solid $card-bg;
        }

        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-color: $card-bg;
        }
    }
}
</style>